import { Link } from "gatsby"
import React from "react"
import styles from "src/components/portfolioSection.module.css"

const PortfolioSection = () => (

  <div className={styles.container}>
    <div className={styles.cell}>
      Hi
    </div>
    <div className={styles.cell}>
      Hi
    </div>
    <div className={styles.cell}>
      Hi
    </div>
    <div className={styles.cell}>
      Hi
    </div>
  </div>
)

export default PortfolioSection
