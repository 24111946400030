import React from "react"
import Layout from "src/components/layout"
import PortfolioHeader from "src/components/portfolioHeader"
import PortfolioSection from "src/components/portfolioSection"

const Coding = () => (
  <Layout>
    <PortfolioHeader highlightFirst />
    <h1>Coding</h1>
    <PortfolioSection />
  </Layout>
)

export default Coding
